import type { Profile } from '@tj-dev/shared-core/dist/types';
import { sendMindboxCourseView } from '../operations';
import { getCourseSlug } from '../util/get-course-slug';
import { getCustomerFromProfile } from '../util/get-customer-from-profile';

export function sendCoursePageView(profile?: Profile) {
  const customer = getCustomerFromProfile(profile);
  const course = getCourseSlug(window.location.pathname);
  sendMindboxCourseView(customer, course);
}
