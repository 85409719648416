// Документация по событиям
// https://wiki.tcsbank.ru/pages/viewpage.action?pageId=2626757920

// Актуальная информация об использовании банковской аналитики
// содержится в README.md репозитория hedge https://gitlab.tcsbank.ru/tj/hedge

import { EventeaQueue } from '@tinkoff/eventea-queue';
import type { SelectSpec } from '@tinkoff/eventea-types';

// Класс-хелпер, который собирает события и отправляет их
// как только eventea становится доступен в window
const eventeaQueue = new EventeaQueue();

type BannerClickedEventPayload = SelectSpec<
  'tjournal',
  'bannerClicked'
>['payload'];

type BannerClickedEventPayloadTj = SelectSpec<
  'tj',
  'events.bannerClicked'
>['payload'];

type BannerShownEventPayload = SelectSpec<'tjournal', 'bannerShown'>['payload'];

type BannerShownEventPayloadTj = SelectSpec<
  'tj',
  'events.bannerShown'
>['payload'];

// На поля defaultParam в типах не обращаем внимания,
// это некий воркэраунд для удобства аналитиков
// Нас же интересует только обязательное тело + специфичные для события параметры
// Лучше всего сверяться с вики-докой по событиям
// https://wiki.tcsbank.ru/pages/viewpage.action?pageId=2614246964

// eventea ожидает, что все отправляемые параметры будут string
// поэтому во избежание ругани с TS и потенциальными undefined полями
// делаем payload аргумент send функций Partial<T>

export function sendBannerClickedEvent(
  payload: Partial<BannerClickedEventPayload>
) {
  eventeaQueue.send({
    project: 'tjournal',
    event: 'bannerClicked',
    payload: payload as BannerClickedEventPayload,
  });
  eventeaQueue.send({
    project: 'tj',
    event: 'events.bannerClicked',
    payload: payload as BannerClickedEventPayloadTj,
  });
}

export function sendBannerShownEvent(
  payload: Partial<BannerShownEventPayload>
) {
  eventeaQueue.send({
    project: 'tjournal',
    event: 'bannerShown',
    payload: payload as BannerShownEventPayload,
  });
  eventeaQueue.send({
    project: 'tj',
    event: 'events.bannerShown',
    payload: payload as BannerShownEventPayloadTj,
  });
}
