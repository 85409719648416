import type { Profile } from '@tj-dev/shared-core/dist/types';
import type { MindboxCustomer } from '../types';

export function getCustomerFromProfile(
  profile: Profile
): MindboxCustomer | undefined {
  if (!profile) {
    return;
  }

  const customer: MindboxCustomer = {
    ids: {
      socialId: profile.id,
    },
  };
  if (profile.email) {
    customer.email = profile.email;
  }

  return customer;
}
