import type { Profile } from '@tj-dev/shared-core/dist/types';
import { getCustomerFromProfile } from '../util/get-customer-from-profile';
import { sendMindboxCollectionView } from '../operations';

export function sendProCollectionPageView(profile?: Profile) {
  const customer = getCustomerFromProfile(profile);
  if (customer && 'email' in customer) {
    delete customer.email;
  }
  sendMindboxCollectionView(customer);
}
