import { VISITED_COURSES } from '../constants';
import { getCourseSlug } from '../util/get-course-slug';

export function saveCoursePageView() {
  const course = getCourseSlug(window.location.pathname);
  const currentViews = localStorage.getItem(VISITED_COURSES);

  localStorage.setItem(
    VISITED_COURSES,
    currentViews ? `${currentViews},${course}` : course
  );
}
