import { VISITED_LESSONS } from '../constants';
import { getLessonSlug } from '../util/get-lesson-slug';

export function saveLessonView() {
  const lesson = getLessonSlug(window.location.pathname);
  const currentViews = localStorage.getItem(VISITED_LESSONS);

  localStorage.setItem(
    VISITED_LESSONS,
    currentViews ? `${currentViews},${lesson}` : lesson
  );
}
