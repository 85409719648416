import { VISITED_ARTICLES } from '../constants';
import { getArticleMeta } from '../util/window';

export function saveArticleView() {
  if (!getArticleMeta()) {
    return;
  }

  const currentList = localStorage.getItem(VISITED_ARTICLES);

  const newArticle = window.location.pathname;
  localStorage.setItem(
    VISITED_ARTICLES,
    currentList ? `${currentList},${newArticle}` : newArticle
  );
}
