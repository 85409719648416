import { addScript } from './util/add-script';
import { sendSavedViews } from './actions/send-saved-views';
import type { DistributionCore, MindboxCustomer } from './types';
import { initMindbox, setDistributionCore } from './util/window';
import {
  getProfile,
  requestLogin,
  subscribeToProfileChange,
} from './util/shared-core-v3';
import { getCustomerFromProfile } from './util/get-customer-from-profile';
import { initPopmechanic } from './core/init-popmechanic';
import { handlePageView } from './core/handle-page-view';
import { AUTH_POPUP_URL_PARAM } from './constants';
import { sendBannerClickedEvent } from './actions/send-banner-clicked-event';
import { sendBannerShownEvent } from './actions/send-banner-shown-event';

let wasUnauthorized = false;
let savedAnauthorizedView = false;

export default async function init() {
  if (typeof window === 'undefined') {
    return;
  }

  // Подключаем основной скрипт mindbox-а
  addScript({
    source: 'https://api.mindbox.ru/scripts/v1/tracker.js',
  });

  // https://developers.mindbox.ru/docs/%D1%82%D1%80%D0%B5%D0%BA%D0%B5%D1%80
  initMindbox();

  // Добавляем колбэки, которые смогут дёргать другие сервисы
  initCore();
  handleView();

  // Проверяем url параметры, чтобы автоматом показать попап авторизации
  showAuthPopupIfNeeded();

  subscribeToProfileChange((profile) => {
    // Если пользователь не авторизован, то profile === null
    // Если инфа ещё грузится, то profile === undefined
    // Завязываться на проверку null или undefined -- плохая практика,
    // потому что это поведение в shared-core может поменяться.
    // Поэтому заводим 2 флага, чтобы не дублировать запросы/сохранение.
    if (!profile) {
      // добавляем флаг, чтобы не посылать просмотры дважды,
      // если пользователь разлогинится/залогинится
      if (!savedAnauthorizedView) {
        handlePageView();
        savedAnauthorizedView = true;
      }
      wasUnauthorized = true;

      return;
    }
    if (!wasUnauthorized) {
      handlePageView(profile);
    }
    sendSavedViews(getCustomerFromProfile(profile) as MindboxCustomer);
  });

  initPopmechanic();
}

function initCore() {
  const core: DistributionCore = {
    sendSavedViews,
    sendBannerClickedEvent,
    sendBannerShownEvent,
  };
  setDistributionCore(core);
}

function handleView() {
  const profile = getProfile();

  // Добавляем на случай, если shared-core успеет прогрузить профиль до того,
  // как начнёт отрабатывать distribution-core
  if (profile) {
    handlePageView(profile);
  }
}

function showAuthPopupIfNeeded() {
  const params = new URLSearchParams(window.location.search);
  const showLoginPopup = params.get(AUTH_POPUP_URL_PARAM);

  if (!showLoginPopup && showLoginPopup !== '') {
    return;
  }

  const profile = getProfile();

  // Если profile === undefined, значит профиль ещё не загрузился, и нужно подписаться на изменение
  if (profile === undefined) {
    subscribeToProfileChange((scProfile) => {
      if (!scProfile && scProfile !== undefined) {
        requestLogin({ pointOfContact: 'email' });
      }
    });
    return;
  }

  if (!profile) {
    requestLogin({ pointOfContact: 'email' });
  }
}
