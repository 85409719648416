import { getArticleMeta } from './window';

/* Договорились, что будем трекать полный просмотр статьи, если юзер доскроллил до комментов, а в случае отсутствия, до рекомендосов */
export function subscribeArticleViewed(cb: VoidFunction) {
  if (!getArticleMeta()) {
    return;
  }

  const commentsContainer = document.getElementById('comments');
  const recommendationsContainer = document.getElementById('showcase');
  const targetContainer = commentsContainer ?? recommendationsContainer;

  if (!targetContainer) {
    cb();
    return;
  }

  const intersectionObserver = new IntersectionObserver(
    (entries, observerInstance) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          cb();
          observerInstance.disconnect();
        }
      }
    }
  );

  intersectionObserver.observe(targetContainer);
}
