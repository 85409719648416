import type { Profile } from '@tj-dev/shared-core/dist/types';
import { getArticleMeta } from '../util/window';
import { getCustomerFromProfile } from '../util/get-customer-from-profile';
import { sendMindboxAddArticleToList } from '../operations';

export function sendAddArticleToList(profile?: Profile) {
  if (!getArticleMeta()) {
    return;
  }

  const customer = getCustomerFromProfile(profile);
  sendMindboxAddArticleToList(customer);
}
