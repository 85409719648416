import type { Profile } from '@tj-dev/shared-core/dist/types';
import { sendMindboxLessonView } from '../operations';
import { getCustomerFromProfile } from '../util/get-customer-from-profile';
import { getLessonSlug } from '../util/get-lesson-slug';

export function sendLessonView(profile?: Profile) {
  const customer = getCustomerFromProfile(profile);
  const lessonSlug = getLessonSlug(window.location.pathname);
  sendMindboxLessonView(customer, lessonSlug);
}
