import type {
  DistributionCore,
  DistributionCoreHolder,
  Mindbox,
} from '../types';

export function getDistributionCore(wnd: DistributionCoreHolder = getHolder()) {
  return wnd.tjlib?.distribution;
}

export function setDistributionCore(core: DistributionCore) {
  const wnd = getHolder();
  wnd.tjlib = wnd.tjlib || {};
  wnd.tjlib.distribution = core;
}

function getHolder() {
  return window as DistributionCoreHolder;
}

export function getMindbox() {
  return getHolder().mindbox as Mindbox;
}

export function isMindboxInitialized() {
  return !!getHolder().mindbox;
}

export function initMindbox() {
  if (isMindboxInitialized()) {
    return;
  }

  const mindbox: Mindbox = (...args: unknown[]) => {
    mindbox.queue.push(args);
  };
  mindbox.queue = [];

  const { DISTRIBUTION_FIREBASE_SENDER_ID: firebaseSenderId, ENV_NAME: env } =
    getConstants() ?? {};

  if (firebaseSenderId) {
    // Документацию по веб-пушам https://developers.mindbox.ru/page/webpush
    mindbox('create', {
      // Свойство endpointId является простым идентификатором в майндбоксе
      endpointId:
        env === 'production' ? 'journal.tinkoff.ru' : 'stage.tjournal.tech',
      firebaseMessagingSenderId: firebaseSenderId,
      serviceWorkerPath: '/mindbox-service-worker.js',
    });

    mindbox('webpush.create');
  } else {
    // Не инициализируем веб-пуши, если в константах отсутствует DISTRIBUTION_FIREBASE_SENDER_ID
    console.error('DISTRIBUTION_FIREBASE_SENDER_ID is not defined');
    mindbox('create');
  }

  (window as DistributionCoreHolder).mindbox = mindbox;
}

export function getArticleMeta() {
  return getHolder().TJ_ARTICLE_META;
}

export function getUraniaMeta() {
  return getHolder().TJ_URANIA_META;
}

export function getConstants() {
  return getHolder().CONSTANTS;
}
