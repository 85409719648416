import {
  sendMindboxAddArticleToList,
  sendMindboxArticleView,
  sendMindboxCourseView,
  sendMindboxProMainPageView,
  sendMindboxLessonView,
} from '../operations';
import {
  PRO_MAIN_PAGE_VISITS,
  VISITED_ARTICLES,
  VIEWED_ARTICLES,
  VISITED_COURSES,
  VISITED_LESSONS,
} from '../constants';
import type { MindboxCustomer } from '../types';

export function sendSavedViews(customer: MindboxCustomer) {
  sendArticleViews(customer);
  sendArticleAddToList(customer);
  sendProMainPageViews(customer);
  sendCoursePageViews(customer);
  sendLessonPageViews(customer);
}

function sendArticleViews(customer: MindboxCustomer) {
  const visitedArticles = localStorage.getItem(VISITED_ARTICLES);

  if (!visitedArticles) {
    return;
  }

  /* Парсим статьи из строки, создаем сет, чтобы оставить только уникальные */
  const uniqueArticlesSet = new Set(visitedArticles.split(','));
  uniqueArticlesSet.forEach((article) => {
    sendMindboxArticleView(customer, article);
  });

  localStorage.removeItem(VISITED_ARTICLES);
}

function sendArticleAddToList(customer: MindboxCustomer) {
  const viewedArticles = localStorage.getItem(VIEWED_ARTICLES);

  if (!viewedArticles) {
    return;
  }

  /* Парсим статьи из строки, создаем сет, чтобы оставить только уникальные */
  const uniqueArticlesSet = new Set(viewedArticles.split(','));
  uniqueArticlesSet.forEach((article) => {
    sendMindboxAddArticleToList(customer, article);
  });

  localStorage.removeItem(VIEWED_ARTICLES);
}

function sendProMainPageViews(customer: MindboxCustomer) {
  const pageVisits = localStorage.getItem(PRO_MAIN_PAGE_VISITS);

  if (!pageVisits) {
    return;
  }

  for (let i = 0; i < parseInt(pageVisits, 10); i++) {
    sendMindboxProMainPageView(customer);
  }

  localStorage.removeItem(PRO_MAIN_PAGE_VISITS);
}

function sendCoursePageViews(customer: MindboxCustomer) {
  const coursesViews = localStorage.getItem(VISITED_COURSES);

  if (!coursesViews) {
    return;
  }

  const coursesList = coursesViews.split(',');
  for (const course of coursesList) {
    sendMindboxCourseView(customer, course);
  }

  localStorage.removeItem(VISITED_COURSES);
}

function sendLessonPageViews(customer: MindboxCustomer) {
  const lessonsViews = localStorage.getItem(VISITED_LESSONS);

  if (!lessonsViews) {
    return;
  }

  const lessonsList = lessonsViews.split(',');
  for (const lesson of lessonsList) {
    sendMindboxLessonView(customer, lesson);
  }

  localStorage.removeItem(VISITED_LESSONS);
}
