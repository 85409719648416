import type { MindboxCustomer } from './types';
import { getOperationData } from './util/get-operation-data';
import { getMindbox } from './util/window';

// Просмотр статьи
// https://tj.mindbox.ru/campaigns/operations/54/help
export function sendMindboxArticleView(
  customer?: MindboxCustomer,
  article?: string
) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('ViewArticle', customer, article);
  mindbox('async', data);
}

// Добавление статьи в список прочитанного
// https://tj.mindbox.ru/campaigns/operations/109/help
export function sendMindboxAddArticleToList(
  customer?: MindboxCustomer,
  article?: string
) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('AddArticleToList', customer, article);
  mindbox('async', data);
}

// Просмотр урока
// https://tj.mindbox.ru/campaigns/operations/715/help
export function sendMindboxLessonView(
  customer?: MindboxCustomer,
  lesson?: string
) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('ViewLessonPage', customer, lesson);
  mindbox('async', data);
}

// Просмотр главной страницы учебника
// https://tj.mindbox.ru/campaigns/operations/713/help
export function sendMindboxProMainPageView(customer?: MindboxCustomer) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('ViewGeneralCoursePage', customer);
  mindbox('async', data);
}

// Просмотр страницы курса
// https://tj.mindbox.ru/campaigns/operations/714/help
export function sendMindboxCourseView(
  customer?: MindboxCustomer,
  course?: string
) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('ViewCoursePage', customer, course);
  mindbox('async', data);
}

// Просмотр страницы сборника курсов
// https://tj.mindbox.ru/campaigns/operations/26134/help
export function sendMindboxCollectionView(customer?: MindboxCustomer) {
  const mindbox = getMindbox();
  if (!mindbox) {
    return;
  }

  const data = getOperationData('ViewCollectionPage', customer);
  mindbox('async', data);
}
