type Options = {
  source: string;
  id?: string;
  position?: 'body' | 'head';
};

export function addScript(options: Options) {
  function perform() {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => loadScript(options));
    } else {
      setTimeout(() => loadScript(options), 1);
    }
  }

  if (document.readyState === 'complete') {
    perform();
  } else {
    window.addEventListener('load', perform);
  }
}

function loadScript({ source, id, position = 'head' }: Options) {
  const script = document.createElement('script');

  script.src = source;
  if (id) {
    script.id = id;
  }

  document[position].appendChild(script);
}
