import { getCore } from '@tj-dev/shared-core';
import type {
  Profile,
  RecentSocialId,
  RequestLoginParams,
} from '@tj-dev/shared-core/dist/types';

export function getProfile(): Profile {
  return getCore()?.profile.get();
}

export function subscribeToProfileChange(cb: (profile: Profile) => void) {
  return getCore()?.profile.subscribe(cb);
}

export function requestLogin(args: RequestLoginParams) {
  return getCore()?.actions.requestLogin(args);
}

export function getRecentSocialId(): RecentSocialId {
  return getCore()?.recentSocialId.get();
}
