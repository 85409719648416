import type {
  MindboxCustomer,
  MindboxOperation,
  MindboxProductKey,
  MindboxRequestData,
} from '../types';

export function getOperationData(
  operation: MindboxOperation,
  customer?: MindboxCustomer,
  page?: string
): MindboxRequestData {
  // В качестве id статей, курсов и страниц в mindbox используются slug-и
  const webSite = page || getSlug(operation, window.location.pathname);

  const productKey: MindboxProductKey =
    operation === 'AddArticleToList' ? 'addProductToList' : 'viewProduct';

  const operationData: MindboxRequestData = {
    operation,
    data: {
      [productKey]: {
        product: {
          ids: {
            webSite,
          },
        },
      },
    },
  };

  if (customer) {
    operationData.data.customer = customer;
  }

  return operationData;
}

function getSlug(operation: MindboxOperation, pathname: string) {
  // для страниц сборников Учебника нужно отправлять slug сборника
  // пример: /pro/collection/time/ отправляем time
  if (operation === 'ViewCollectionPage') {
    return pathname.split('/')[3] || pathname;
  }
  return pathname;
}
