import type { BannerEventParams } from '../types';
import { sendBannerShownEvent as sendBannerShownAnalyticsEvent } from '../analytics';
import { getArticleMeta } from '../util/window';
import { getRecentSocialId } from '../util/shared-core-v3';

export function sendBannerShownEvent(params: BannerEventParams) {
  if (!params.id || !params.href) {
    return;
  }

  const articleMeta = getArticleMeta();
  const recentSocialId = getRecentSocialId();

  sendBannerShownAnalyticsEvent({
    entityType: 'mindbox.popup',
    socialId: String(recentSocialId ?? ''),
    ...params,
    ...articleMeta,
  });
}
