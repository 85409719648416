import type { ArticleMeta, DistributionCoreHolder } from '../types';
import { addScript } from '../util/add-script';
import { getArticleMeta } from '../util/window';

export function initPopmechanic() {
  const meta = getArticleMeta();

  if (meta) {
    setPopmechanicData(meta);
  }

  // Выходим если попмеханики уже загружены чем-то другим
  if ('PopMechanic' in window) {
    return;
  }

  addScript({
    source: 'https://static.popmechanic.ru/service/loader.js?c=5263',
    id: 'popmechanic-script',
    position: 'body',
  });
}

function setPopmechanicData(meta: ArticleMeta) {
  const flowsString = meta.articleFlows.join(',');
  const tagsString = meta.articleTags.join(',');

  (window as DistributionCoreHolder).PopMechanicPageData = {
    type: 'product',
    product: {
      id: window.location.pathname,
      name: window.document.title,
      pictureUrl: getArticleImage(),
      category: tagsString,
      categoryId: flowsString,
    },
  };
}

function getArticleImage() {
  const ogEl = document.querySelector(
    'meta[property="og:image:secure_url"]'
  ) as HTMLMetaElement;
  return ogEl ? ogEl.content : '';
}
