import { VIEWED_ARTICLES } from '../constants';
import { getArticleMeta } from '../util/window';

export function saveAddArticleToList() {
  if (!getArticleMeta()) {
    return;
  }

  const currentList = localStorage.getItem(VIEWED_ARTICLES);

  const newArticle = window.location.pathname;
  localStorage.setItem(
    VIEWED_ARTICLES,
    currentList ? `${currentList},${newArticle}` : newArticle
  );
}
